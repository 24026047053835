import React, { createContext, useReducer, useContext } from 'react'
// import appReducer from './appReducer';

export const PortfolioContext = createContext();


// create a provider with parameters to pass in from app parent (Layout.js)
const PortfolioProvider = ({appReducer, initialState, children}) => {
  return (
    <PortfolioContext.Provider value={useReducer(appReducer, initialState)}>
      { children }
    </PortfolioContext.Provider>
  )
}

// 
export const useStateValue = () => useContext(PortfolioContext)

export default PortfolioProvider